import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMemberByJoinCode, clearJoinCodeError } from '../../../actions/program';
import { createSubscription } from '../../../actions/payment';
import { TextField, InputAdornment, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import JoinIcon from '@mui/icons-material/LockOpen';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const JoinCode = () => {
  const dispatch = useDispatch();
  const [joinCode, setJoinCode] = useState('');
  const error = useSelector((state) => state.program.joinCodeError);
  const success = useSelector((state) => state.program.joinCodeSuccess);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const sendCode = async () => {
    if (joinCode.length === 8) {
      try {
        const result = await dispatch(addMemberByJoinCode(joinCode));
        
        if (result?.joinProgram?.price > 0) {
          const { data } = await dispatch(createSubscription(result.joinProgram._id));
          if (!data.free) {
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: data.sessionId });
            return;
          }
        }
      } catch (err) {
        console.error('Error joining program:', err);
      }
    }
  };

  const inviteStyle = {
    borderRadius: '10px',
    width: '300px'
  };

  const handleJoinCodeChange = (e) => {
    const value = e.target.value;
    if (/^[0-9a-fA-F]*$/.test(value) && value.length <= 8) {
      setJoinCode(value);
    }
  };

  useEffect(() => {
    if (error) {
      setDialogMessage(error);
      setDialogOpen(true);
    } else if (success) {
      setDialogMessage("Successfully joined the group!");
      setDialogOpen(true);
    }
  }, [error, success]);

  const handleClose = () => {
    setDialogOpen(false);
    dispatch(clearJoinCodeError());
  };

  return (
    <>
      <TextField 
        name="title" 
        variant="outlined" 
        label="Join Code" 
        size="small"
        sx={{ marginTop: "10px" }}
        value={joinCode} 
        onChange={handleJoinCodeChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton 
                aria-label="join" 
                color="primary" 
                onClick={sendCode}
                disabled={joinCode.length !== 8}
              >
                <JoinIcon />
              </IconButton>
            </InputAdornment>
          ),
          sx: inviteStyle, 
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Join Code Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JoinCode;