/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as math from "./math";
const color = "aqua";
const boundingBoxColor = "red";
const lineWidth = 2;


export function drawPoint(ctx, y, x, r, color) {
  ctx.beginPath();
  ctx.arc(x, y, r, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();
};

/**
* Draws a line on a canvas, i.e. a joint
*/
export function drawSegment([ay, ax], [by, bx], color, scale, ctx, width=lineWidth) {
  ctx.beginPath();
  ctx.moveTo(ax * scale, ay * scale);
  ctx.lineTo(bx * scale, by * scale);
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
  ctx.stroke();
}

// draws edges on the canvas
export function drawEdges(ctx, tensor, r) {
  for (let i = 0; i < tensor.length; i++) {
    for (let j = 0; j < tensor[i].length; j++) {
      if (tensor[i][j] > 0.7) {
        drawPoint(ctx, i, j, r, color);
      }
    }
  }  
}



export function draw3DSkeleton(keypoints, minConfidence, ctx, scale=1) {
  const pairs = [[11,12],[11,13],[13,15],[12,14],[14,16],[11,23],[12,24],[23,25],[24,26],[25,27],[26,28],[23,24]];
  
  for (let i = 0; i < pairs.length; i++) {
    const kp1 = keypoints[pairs[i][0]];
    const kp2 = keypoints[pairs[i][1]];
    if (kp1.score > minConfidence || kp2.score > minConfidence) {
      drawSegment(
        [kp1.y, kp1.x],
        [kp2.y, kp2.x],
        color,
        scale,
        ctx
      );
    }
  }
}

export function draw2DSkeleton(keypoints, minConfidence, ctx, scale=1) {
  const pairs = [[5,6],[5,7],[7,9],[6,8],[8,10],[5,11],[6,12],[11,13],[12,14],[13,15],[14,16],[11,12]];
  
  for (let i = 0; i < pairs.length; i++) {
    const kp1 = keypoints[pairs[i][0]];
    const kp2 = keypoints[pairs[i][1]];
    if (kp1.score > minConfidence || kp2.score > minConfidence) {
      drawSegment(
        [kp1.y, kp1.x],
        [kp2.y, kp2.x],
        color,
        scale,
        ctx
      );
    }
  }
}

// export function drawReps(ctx, x, y, text, size) {
//   // Set font size and style for the text
//   const plateTextSize = size * 0.6; // Text size for plates
//   ctx.font = `${plateTextSize}px Arial`;
//   ctx.textBaseline = 'middle';
//   ctx.textAlign = 'right'; // Align text to the right

//   // Set the constant width for the box (enough for up to 4 digits)
//   const maxWidth = 2*ctx.measureText('9').width; // Maximum width for 4 digits (e.g., '9999')
//   const padding = size * 0.5; // Padding around text

//   // Dimensions for the box (constant width)
//   const boxWidth = maxWidth + padding * 3; // Box width for 4 digits
//   const boxHeight = size * 1; // Height of the box

//   // Draw the black box
//   ctx.beginPath();
//   ctx.rect(
//     x,
//     y - boxHeight / 2,
//     boxWidth,
//     boxHeight
//   );
//   ctx.fillStyle = 'rgba(0, 0, 0, 0.75)';
//   ctx.fill();
//   ctx.closePath();

//   // Draw the text aligned to the right inside the box
//   ctx.fillStyle = '#FFF'; // Text color (white)
//   ctx.fillText(
//     text,
//     x + boxWidth - padding, // Align the text to the right edge of the box
//     y // Center the text vertically
//   );
// }


export function drawReps(ctx, x, y, text) {
  // Fixed dimensions for the box
  const boxWidth = 150; // Fixed rectangle width
  const boxHeight = 60; // Fixed rectangle height
  const padding = 10; // Padding around the text

  // Start with a maximum font size based on the box height
  let fontSize = boxHeight * 0.6;
  ctx.font = `${fontSize}px Arial`;
  const textWidth = ctx.measureText(text).width;

  // Scale down the font size if the text width exceeds the box width
  if (textWidth > boxWidth - padding * 2) {
    fontSize *= (boxWidth - padding * 2) / textWidth;
    ctx.font = `${fontSize}px Arial`;
  }

  // Draw the black box
  ctx.beginPath();
  ctx.rect(
    x, // Start the rectangle at x
    y - boxHeight / 2, // Center the rectangle vertically
    boxWidth, // Fixed rectangle width
    boxHeight // Fixed rectangle height
  );
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
  ctx.fill();
  ctx.closePath();

  // Draw the autoscaled text centered inside the box
  ctx.fillStyle = '#FFF'; // Text color (white)
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center'; // Align text to the center
  ctx.fillText(
    text,
    x + boxWidth / 2, // Center the text horizontally
    y // Center the text vertically
  );
}




/**
 * Draws text surrounded by a filled rectangle with transparency, left-aligned.
 * 
 * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
 * @param {number} x - The x-coordinate of the text's starting position.
 * @param {number} y - The y-coordinate of the text's center.
 * @param {string} text - The text to display.
 * @param {number} size - The font size in pixels.
 */
export function drawLeftText(ctx, x, y, text, size) {
  // Set font size and style for the text
  const phaseTextSize = size * 0.6; // Text size scaling factor
  ctx.font = `${phaseTextSize}px Arial`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'left'; // Align text to the left

  // Measure the text and calculate box dimensions
  const textMetrics = ctx.measureText(text);
  const textWidth = textMetrics.width;
  const padding = size * 0.5; // Padding around the text
  const boxWidth = textWidth + padding * 2; // Box width including padding
  const boxHeight = size * 1; // Box height scaling factor

  // Draw the semi-transparent rectangle
  ctx.beginPath();
  ctx.rect(
    x, // Start the rectangle at x
    y - boxHeight / 2, // Center the rectangle vertically
    boxWidth, // Rectangle width
    boxHeight // Rectangle height
  );
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
  ctx.fill();
  ctx.closePath();

  // Draw the text inside the box
  ctx.fillStyle = '#FFF'; // White text
  ctx.fillText(
    text,
    x + padding, // Position text with left padding
    y // Center the text vertically
  );
}


// export function drawTitle(ctx, x, y, text, size) {
//   // Set font size and style for the text
//   const phaseTextSize = size * 0.6; // Text size scaling factor
//   ctx.font = `${phaseTextSize}px Arial`;
//   ctx.textBaseline = 'middle';
//   ctx.textAlign = 'left'; // Align text to the left

//   // Fixed box dimensions
//   const boxWidth = 300; // Fixed rectangle width
//   const padding = size * 0.5; // Padding around the text
//   const boxHeight = size * 1; // Box height scaling factor

//   // Measure the text and truncate with ellipsis if necessary
//   const maxTextWidth = boxWidth - padding * 2; // Maximum width for text
//   let displayText = text;

//   if (ctx.measureText(text).width > maxTextWidth) {
//     while (ctx.measureText(displayText + '...').width > maxTextWidth && displayText.length > 0) {
//       displayText = displayText.slice(0, -1); // Remove the last character
//     }
//     displayText += '...'; // Add ellipsis
//   }

//   // Draw the semi-transparent rectangle
//   ctx.beginPath();
//   ctx.rect(
//     x, // Start the rectangle at x
//     y - boxHeight / 2, // Center the rectangle vertically
//     boxWidth, // Fixed rectangle width
//     boxHeight // Rectangle height
//   );
//   ctx.fillStyle = 'rgba(0, 0, 0, 0.75)'; // Semi-transparent black
//   ctx.fill();
//   ctx.closePath();

//   // Draw the text inside the box
//   ctx.fillStyle = '#FFF'; // White text
//   ctx.fillText(
//     displayText,
//     x + padding, // Position text with left padding
//     y // Center the text vertically
//   );
// }

export function drawTitle(ctx, x, y, text) {
  // Fixed dimensions for the rectangle
  const boxWidth = 300; // Fixed rectangle width
  const boxHeight = 50; // Fixed rectangle height
  const padding = 10; // Padding around the text
  const maxFontSize = 25; // Maximum font size

  // Start with the maximum font size
  let fontSize = maxFontSize;
  ctx.font = `${fontSize}px Arial`;
  const textWidth = ctx.measureText(text).width;

  // Scale the font size proportionally to fit within the box width
  if (textWidth > boxWidth - padding * 2) {
    fontSize *= (boxWidth - padding * 2) / textWidth;
    ctx.font = `${fontSize}px Arial`;
  }

  // Ensure the font size does not exceed the maximum allowed size
  fontSize = Math.min(fontSize, maxFontSize);
  ctx.font = `${fontSize}px Arial`;

  // Draw the semi-transparent rectangle
  ctx.beginPath();
  ctx.rect(
    x, // Start the rectangle at x
    y - boxHeight / 2, // Center the rectangle vertically
    boxWidth, // Fixed rectangle width
    boxHeight // Fixed rectangle height
  );
  ctx.fillStyle = 'rgba(0, 0, 0, 0.75)'; // Semi-transparent black
  ctx.fill();
  ctx.closePath();

  // Draw the text inside the box
  ctx.fillStyle = '#FFF'; // White text
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center'; // Center the text horizontally
  ctx.fillText(
    text,
    x + boxWidth / 2, // Center the text inside the rectangle
    y // Center the text vertically
  );
}



/**
 * Draws text surrounded by a filled rectangle with transparency, right-aligned.
 * 
 * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
 * @param {number} x - The x-coordinate for the right edge of the rectangle.
 * @param {number} y - The y-coordinate of the text's center.
 * @param {string} text - The text to display.
 * @param {number} size - The font size in pixels.
 */
export function drawRightText(ctx, x, y, text, size) {
  // Set font size and style for the text
  const phaseTextSize = size * 0.6; // Text size scaling factor
  ctx.font = `${phaseTextSize}px Arial`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'right'; // Align text to the right

  // Measure the text and calculate box dimensions
  const textMetrics = ctx.measureText(text);
  const textWidth = textMetrics.width;
  const padding = size * 0.5; // Padding around the text
  const boxWidth = textWidth + padding * 2; // Box width including padding
  const boxHeight = size * 1.2; // Box height scaling factor

  // Draw the semi-transparent rectangle
  ctx.beginPath();
  ctx.rect(
    x - boxWidth, // Start the rectangle so it aligns to the right
    y - boxHeight / 2, // Center the rectangle vertically
    boxWidth, // Rectangle width
    boxHeight // Rectangle height
  );
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
  ctx.fill();
  ctx.closePath();

  // Draw the text inside the box
  ctx.fillStyle = '#FFF'; // White text
  ctx.fillText(
    text,
    x - padding, // Position text with right padding
    y // Center the text vertically
  );
}



/**
* Draw pose keypoints onto a canvas
*/
export function drawKeypoints(keypoints, minConfidence, ctx, scale=1, width=3) {
  for (let i = 0; i < keypoints.length; i++) {
    if (keypoints[i].score < minConfidence) {
      continue;
    }

    drawPoint(ctx, keypoints[i].y * scale, keypoints[i].x * scale, width, color);
  }
}

// draws feedback lines over the incorrect body parts
export function drawFeedback(keypoints, feedback, flat, minConfidence, ctx, scale=1, width=3) {
  let midShoulder = null;
  let midHip = null;
  let midAnkle = null;
  if (flat) {
    midShoulder = (math.midpoint([keypoints[5].x, keypoints[5].y], [keypoints[6].x, keypoints[6].y]));
    midHip = (math.midpoint([keypoints[11].x, keypoints[11].y], [keypoints[12].x, keypoints[12].y]));
    midAnkle = (math.midpoint([keypoints[15].x, keypoints[15].y], [keypoints[16].x, keypoints[16].y]));
  }
  else {
    midShoulder = (math.midpoint([keypoints[11].x, keypoints[11].y, keypoints[11].z], [keypoints[12].x, keypoints[12].y, keypoints[12].z]));
    midHip = (math.midpoint([keypoints[23].x, keypoints[23].y, keypoints[23].z], [keypoints[24].x, keypoints[24].y, keypoints[24].z]));
    midAnkle = (math.midpoint([keypoints[27].x, keypoints[27].y, keypoints[27].z], [keypoints[28].x, keypoints[28].y, keypoints[28].z]));
  }
  
  
  
  keypoints.push({
    x: midShoulder[0],
    y: midShoulder[1]
  });
  keypoints.push({
    x: midHip[0],
    y: midHip[1]
  });
  keypoints.push({
    x: midAnkle[0],
    y: midAnkle[1]
  });
  for (let i = 0; i < feedback.length; i++) {
    // if (keypoints[feedback[i][1]].score < minConfidence) {
    //   continue;
    // }
    drawSegment(
      [keypoints[feedback[i][0]].y, keypoints[feedback[i][0]].x],
      [keypoints[feedback[i][1]].y, keypoints[feedback[i][1]].x],
      boundingBoxColor,
      scale,
      ctx
    )
    drawSegment(
      [keypoints[feedback[i][1]].y, keypoints[feedback[i][1]].x],
      [keypoints[feedback[i][2]].y, keypoints[feedback[i][2]].x],
      boundingBoxColor,
      scale,
      ctx
    );
    drawPoint(ctx, keypoints[feedback[i][1]].y * scale, keypoints[feedback[i][1]].x * scale, width, boundingBoxColor);
  }
}

/**
* Converts an arary of pixel data into an ImageData object
*/
export async function renderToCanvas(a, ctx) {
  const [height, width] = a.shape;
  const imageData = new ImageData(width, height);

  const data = await a.data();

  for (let i = 0; i < height * width; ++i) {
    const j = i * 4;
    const k = i * 3;

    imageData.data[j + 0] = data[k + 0];
    imageData.data[j + 1] = data[k + 1];
    imageData.data[j + 2] = data[k + 2];
    imageData.data[j + 3] = 255;
  }

  ctx.putImageData(imageData, 0, 0);

}

/**
* Draw an image on a canvas
*/
export function renderImageToCanvas(image, size, canvas) {
  canvas.width = size[0];
  canvas.height = size[1];
  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0);
}

export function rotateBase64Image(srcBase64, degrees) {
  const canvas = document.createElement('canvas');
  let ctx = canvas.getContext("2d");
  let image = new Image();
  image.src = srcBase64
  return new Promise(resolve => {
  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(degrees * Math.PI / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    resolve(canvas.toDataURL());
    };
  });
}

export function drawMetrics(x, y, size, ctx, control, explosiveness) {
  const barWidth = size * 2; // Length of the bar
  const barHeight = size * 0.4; // Height of each bar
  const spacing = size * 0.6; // Space between bars
  const fontSize = size * 0.5; // Font size for labels
  // Determine maximum label width to align bars
  const labels = ["Control:", "Explosiveness:"];
  ctx.font = `${fontSize}px Arial`;
  const maxLabelWidth = Math.max(...labels.map(label => ctx.measureText(label).width));

  const componentWidth = maxLabelWidth + barWidth + size * 2; // Width for the entire component
  const componentHeight = (barHeight + spacing) * 2 - spacing; // Height for the entire component

  // Adjust background position and size for proper placement
  const bgPadding = size * 0.5; // Padding around the background
  ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Slightly opaque black
  ctx.fillRect(x, y+bgPadding * .5, 300, 72.5);

  // Helper function to draw a single bar
  function drawBar(label, value, x, y) {
      // Draw label on the same line as the bar
      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = "white";
      ctx.textAlign = "left";
      ctx.fillText(label, x, y + barHeight / 2 + fontSize / 3);

      // Draw bar background
      const barX = x + maxLabelWidth + size; // Align bars by using maxLabelWidth
      ctx.fillStyle = "#ddd";
      ctx.fillRect(barX, y, barWidth, barHeight);

      // Draw bar foreground based on value
      ctx.fillStyle = "#4caf50";
      ctx.fillRect(barX, y, (value / 100) * barWidth, barHeight);

      // Draw border around the bar
      ctx.strokeStyle = "white";
      ctx.strokeRect(barX, y, barWidth, barHeight);
  }

  // Draw the "Control" bar
  drawBar("Control:", control, x + bgPadding, y + bgPadding);

  // Draw the "Explosiveness" bar below the "Control" bar
  drawBar("Explosiveness:", explosiveness, x + bgPadding, y + bgPadding + barHeight + spacing);
}


