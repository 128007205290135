import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProgram, joinProgram, acceptProgramInvitation, removeJoinRequest } from '../../../actions/program';
import { createSubscription } from '../../../actions/payment';
import GenericCard from '../../Content/Card';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ProgramCard = ({ item, isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    dispatch(deleteProgram(id));
  };

  const handleManage = (id) => {
    navigate(`/programs/${id}`);
  };

  const handleJoin = async (id) => {
    if (item.price > 0) {
      const response = await dispatch(createSubscription(id));
      if (response && response.data) {
        if (!response.data.free) {
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
          return;
        }
      }
    }
    dispatch(joinProgram(id));
  };

  const handleAccept = async (id) => {
    if (item.price > 0) {
      const response = await dispatch(createSubscription(id));
      if (response && response.data) {
        if (!response.data.free) {
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
          return;
        }
      }
    }
    dispatch(acceptProgramInvitation(id));
  };

  const handleWithdraw = (id) => {
    dispatch(removeJoinRequest(id));
  };

  return (
    <GenericCard
      item={{ ...item, type: 'program' }}
      onLike={null}
      onBookmark={null}
      onDelete={handleDelete}
      onEdit={null}
      onManage={handleManage}
      onJoin={handleJoin}
      onAccept={handleAccept}
      onWithdraw={handleWithdraw}
      isBookmarked={false}
      isLiked={false}
      isMobile={isMobile}
      programData={null}
    />
  );
};

export default ProgramCard;