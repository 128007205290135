import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SubscriptionForm } from './SubscriptionForm';


const stripePromise = loadStripe('your-publishable-key');

const Subscription = () => {
  return (
    <Elements stripe={stripePromise}>
        <SubscriptionForm />
    </Elements>
  );
}

export default Subscription;