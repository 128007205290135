import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/UploadFileOutlined';
import UploadCompleteIcon from '@mui/icons-material/TaskOutlined';
import imageCompression from 'browser-image-compression';


export default function FileInput(props) {
    const [baseImage, setBaseImage] = useState(props.val);
    const [error, setError] = useState("");
    const SIZE_LIMIT = 400; // in kb

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: SIZE_LIMIT / 1024,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        };
        
        try {
            const compressedBlob = await imageCompression(file, options);
            // Create a new File object from the compressed blob
            return new File([compressedBlob], file.name, {
                type: compressedBlob.type,
                lastModified: new Date().getTime()
            });
        } catch (error) {
            console.error('Error compressing image:', error);
            setError('Error compressing image');
            return null;
        }
    };

    const uploadImage = async (e) => {
        setError("");
        const file = e.target.files[0];

        // security checks
        if (!file) return;
        
        try {
            let finalFile = file;
            let finalBase64;
            
            // Always compress if file is too large
            if (file.size > 1024 * SIZE_LIMIT) {
                finalFile = await compressImage(file);
                if (!finalFile) return;
            }
            
            // Convert to base64 if needed
            if (props.base64) {
                finalBase64 = await convertBase64(finalFile);
                setBaseImage(finalBase64);
                props.onDone({ base64: finalBase64, file: finalFile });
            } else {
                finalBase64 = await convertBase64(finalFile);
                setBaseImage(finalBase64);
                props.onDone(finalFile);
            }
        } catch (error) {
            console.error('Error processing image:', error);
            setError('Error processing image');
        }
    };

    useEffect(() => {
        setBaseImage(props.val);
    }, [props.val]);    

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }; 
    
    return (
        <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
            <label>
                <div>
                    <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={(e) => uploadImage(e)} />   
                    <Button position="absolute" color="primary" component='span' startIcon={!baseImage || baseImage?.length === 0 ? <FileUploadIcon/> : <UploadCompleteIcon/>} >
                        {props.children}
                    </Button>
                </div>
                
                <img style={{margin: '0 auto', height: '75px', maxWidth: '130px' }} src={baseImage} />
            </label>
            <Typography variant="caption" color="error">{error}</Typography>
        </div>
    )
}