import { CREATE_STRIPE_CONNECTED_ACCOUNT, CREATE_SUBSCRIPTION, CANCEL_SUBSCRIPTION, CHECK_SUBSCRIPTION_STATUS, GET_STRIPE_LOGIN_LINK, POST_PAYMENT, GET_PAYMENT_ACCOUNT, GET_STRIPE_ACCOUNT_STATUS } from '../constants/actionTypes.js';
import * as api from '../api/index.js';

// Create a Stripe connected account for sellers using their email.
export const createStripeConnectedAccount = (formData) => async (dispatch) => {
  try {
    console.log('Creating Stripe connected account...');
    const { data } = await api.createStripeConnectedAccount(formData);
    
    // Dispatch the Stripe account creation
    dispatch({ type: CREATE_STRIPE_CONNECTED_ACCOUNT, payload: { data } });
    
    // Update the profile in localStorage
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      profile.result = {
        ...profile.result,
        stripeAccountId: data.account.id,
        stripeAccountStatus: 'pending'
      };
      localStorage.setItem('profile', JSON.stringify(profile));
    }
    
    // Redirect to Stripe onboarding
    if (data.redirectUrl) {
      window.location.href = data.redirectUrl;
    }
    
    return data;
  } catch (error) {
    console.error('Error in createStripeConnectedAccount:', error);
    throw error;
  }
};

// Get the Stripe account status for the current user
export const getStripeAccountStatus = () => async (dispatch) => {
  try {
    const { data } = await api.getStripeAccountStatus();
    dispatch({ type: GET_STRIPE_ACCOUNT_STATUS, payload: data });
    return data;
  } catch (error) {
    console.error('Error fetching account status:', error);
  }
};

// Get the payment account details for the current user
export const getPaymentAccount = () => async (dispatch) => {
  try {
    const { data } = await api.getPaymentAccount();
    dispatch({ type: GET_PAYMENT_ACCOUNT, payload: data });
  } catch (error) {
    console.error('Error fetching payment account:', error);
  }
};

// Get the Stripe login link for the user's profile.
export const getStripeLoginLink = () => async (dispatch) => {
  try {
    console.log('getStripeLoginLink action');
    const { data } = await api.getStripeLoginLink();
    console.log('getStripeLoginLink action data received: ', data);
    
    // Dispatching the data to the reducer
    dispatch({ type: GET_STRIPE_LOGIN_LINK, payload: { data } });
  } catch (error) {
    console.log(error);
  }
};

// Create a subscription session for the user.
export const createSubscription = (programId) => async (dispatch) => {
  try {
    const { data } = await api.createSubscription({ programId });
    dispatch({ type: CREATE_SUBSCRIPTION, payload: { data } });
    if (data.url) window.location.href = data.url;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Cancel a subscription for the user.
export const cancelSubscription = (id) => async (dispatch) => {
  try {
    console.log('cancelSubscription action');
    const { data } = await api.cancelSubscription(id);
    console.log('cancelSubscription action data received: ', data);
    
    // Dispatching the data to the reducer
    dispatch({ type: CANCEL_SUBSCRIPTION, payload: { data } });
  } catch (error) {
    console.log(error);
  }
};

// Check the subscription status for the user.
export const checkSubscriptionStatus = (id) => async (dispatch) => {
  try {
    console.log('checkSubscriptionStatus action');
    const { data } = await api.checkSubscriptionStatus(id);
    console.log('checkSubscriptionStatus action data received: ', data);
    
    // Dispatching the data to the reducer
    dispatch({ type: CHECK_SUBSCRIPTION_STATUS, payload: { data } });
  } catch (error) {
    console.log(error);
  }
};

export const postPayment = (id) => async (dispatch) => {
  try {
    console.log('postPayment action');
    const { data } = await api.postPayment(id);
    console.log('postPayment action data received: ', data);
    
    // Dispatching the data to the reducer
    dispatch({ type: POST_PAYMENT, payload: { data } });

    // Redirect the user to the Stripe checkout URL
    window.location.href = data.url;
  } catch (error) {
    console.log(error);
  }
};