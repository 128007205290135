import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography, Divider, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { getVideoById, incrementVideoViews } from '../../actions/video';
import VideoPlayer from './VideoPlayer';
import CommentSection from '../Comment/CommentSection';

const VideoDetails = () => {
  const video = useSelector((state) => state.video.video);
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useTheme();

  useEffect(() => {
    dispatch(getVideoById(id));
    dispatch(incrementVideoViews(id));
  }, [id, dispatch, getVideoById]);

  if (!video) return null;

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: '30px', borderRadius: '10px', marginTop: '10px', marginBottom: '10px' }} elevation={6}>
        <div>
          <Typography variant="h3" color="primary" component="h2" sx={{ wordBreak: 'break-word' }}>{video.title}</Typography>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Created by
            <Link to={`/profile/${video.creator._id}`} style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}>
              {` ${video.creator.username}`}
            </Link>
          </Typography>
          <Typography variant="body1" color={theme.palette.text.secondary}>{moment(video.createdAt).fromNow()}</Typography>

          <Divider style={{ margin: '20px 0px' }} />

          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} md={6} sm={8}>
              <VideoPlayer url={video.videoUrl} width="auto" height="auto" playing={false} />
            </Grid>
                        
            <Grid item xs={12} md={6} sm={8}>
              <Typography gutterBottom variant="body1" component="p">{video.description}</Typography>
            </Grid>
          </Grid>
            
          <Divider style={{ margin: '20px 0' }} />
          <CommentSection 
            type="video" 
            id={video._id} 
            programId={video.program?._id}
          />
        </div>
      </Paper>
    </Container>
  );
};

export default VideoDetails;