import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Typography, Stack, Switch, Container, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

import { getLogsByCreator, getTwoMonthLogsByCreator } from '../../actions/log';
import Log from './Log';
import Calendar from './Calendar';
import Stats from './Stats';

const Logs = () => {
  // Select logs and two-month logs from the Redux store
  const logs = useSelector((state) => state.log.logs);
  const twoMonthLogs = useSelector((state) => state.log.twoMonths);
  // Get the logged-in user's profile from local storage
  const user = JSON.parse(localStorage.getItem('profile'));
  
  // Initialize dispatch function from Redux
  const dispatch = useDispatch();
  
  // Access the current theme (e.g., for colors)
  const theme = useTheme();
  
  // Initialize current day and date states
  const currentDay = new Date();
  const [dateVar, setDate] = useState(new Date());
  
  // Track the selected month (0 for the current month, -1 for the previous, etc.)
  const [monthVar, setMonthVar] = useState(0);

  // Toggle switch to view followed users' logs
  const [checked, setChecked] = useState(false);

  // Variables to store today's logs and summary data for the current month
  var today = [];
  var monthSummary = new Map();
  var summary = new Map();
  var monthKeys = [];
  var monthNumbers = [];
  
  // Array of month names for display purposes
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  
  // Handle change in the switch to toggle between user's logs and followed users' logs
  const handleChange = () => {
    setChecked(!checked);
  };

  // Fetch two-month logs for the user on component mount
  useEffect(()=>{
    dispatch(getTwoMonthLogsByCreator(user.result._id));
  }, []);

  // Fetch logs by creator whenever the selected month or switch state changes
  useEffect(() => {
    if (user != null) {
      dispatch(getLogsByCreator(!checked, monthVar));
    }
  }, [monthVar, checked]);
  
  // Populate 'today' array with logs from the selected date
  for (let i = 0; i < logs.length; i++) {
    const k = logs[logs.length - i - 1];
    const localTime = moment(k.createdAt).format("YYYY-MM-DDTHH:mm:ss");
    let date = new Date(localTime);
    if (date.getDate() === dateVar.getDate()) {
      today.push(k);
    }
    if (!monthNumbers.includes(date.getDate())) {
      monthNumbers.push(date.getDate());
    }
  }

  // Summarize today's logs by exercise type
  for (let i = 0; i < today.length; i++) {
    for (let j = 0; j < today[i].reps.length; j++) {
      const type = today[i].exerciseIds[j];
      const reps = today[i].reps[j];
      const name = today[i].exerciseNames[j];
      if (!summary.has(type)) {
        summary.set(type, [reps, name]);
      } else {
        summary.set(type, [summary.get(type)[0] + reps, name]);
      }
    } 
  }

  // Summarize logs for the current month by exercise type
  for (let i = 0; i < logs.length; i++) {
    for (let j = 0; j < logs[i].reps.length; j++) {
      const type = logs[i].exerciseIds[j];
      const reps = logs[i].reps[j];
      const name = logs[i].exerciseNames[j];
      
      if (type === undefined) {
        console.warn(`Undefined type at log index ${i}, rep index ${j}`, logs[i]);
        continue;  // Skip this iteration if type is undefined
      }
      
      if (!monthSummary.has(type)) {
        monthSummary.set(type, [reps, name]);
      } else {
        monthSummary.set(type, [monthSummary.get(type)[0] + reps, name]);
      }
    }
  }  

  // Convert monthSummary keys to an array for display
  monthKeys = Array.from(monthSummary.keys());

  // Create a map of logs by exercise ID for the past two months
  let logMap = new Map();
  for (let i = 0; i < twoMonthLogs.length; i++) {
    for (let j = 0; j < twoMonthLogs[i].exerciseIds.length; j++) {
      let id = twoMonthLogs[i].exerciseIds[j];
      let logArray = logMap.get(id);
      if (logArray === undefined) {
        logMap.set(id, [{date: twoMonthLogs[i].createdAt, name: twoMonthLogs[i].exerciseNames[j], reps: twoMonthLogs[i].reps[j]}]);
      } else {
        logMap.set(id, [...logArray, {date: twoMonthLogs[i].createdAt, name: twoMonthLogs[i].exerciseNames[j], reps: twoMonthLogs[i].reps[j]}]);
      }
    }
  }

  // Function to check if two dates are within one week
  function areDatesWithinOneWeek(date1, date2) {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    const diff = Math.abs(time1 - time2);
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    return diff <= oneWeek;
  }

  // Calculate total exercises logged in the past week and two months
  let totalWeek = 0;
  let total2Months = 0;
  for (let i = 0; i < twoMonthLogs.length; i++) {
    if (areDatesWithinOneWeek(new Date(twoMonthLogs[i].createdAt), currentDay)) {
      totalWeek += twoMonthLogs[i].exerciseIds.length;
    }
    total2Months += twoMonthLogs[i].exerciseIds.length;
  }

  // Styles for Paper and Typography components
  const paperStyle = { 
    padding: '20px', 
    margin: "10px auto",
    minHeight: '410px',
    maxHeight: '410px', 
    overflow: 'auto',
    border: '2px solid',
    borderColor: theme.palette.text.secondary,
  };

  const headingStyle = {
    margin: '0px 0px 10px',
    color: theme.palette.primary.main,
    textAlign: 'center'
  };

  return (
    <div>
      {/* Display "Please Sign In" if the user is not logged in */}
      {(user == null) && (
        <Paper elevation={6}>
          <Typography variant="h6" align="center">
            Please Sign In
          </Typography>
        </Paper>
      )}

      {/* Display logs, calendar, and summary if the user is logged in */}
      { (user !== null) && (
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
            <Grid item style={{margin: 'auto'}} xs={12} sm={12} md={4} lg={4}>
              <Paper elevation={3} sx={paperStyle}>
                <Typography variant="h5" sx={headingStyle}>
                  Calendar
                </Typography>
                {/* Calendar component with date and month variable setters */}
                <Calendar setDate={setDate} setMonthVar={setMonthVar} data={monthNumbers}/>

                {/* Switch to toggle between user's logs and followed users' logs */}
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                  />
                  <Typography variant="body1" sx={{ color: checked ? theme.palette.primary.main : theme.palette.text.secondary}}>
                    Show Followed Users' Logs
                  </Typography>
                </Stack>
              </Paper>
            </Grid>

            {/* Display today's logs */}
            <Grid item style={{margin: 'auto'}} xs={12} sm={12} md={4} lg={4}>
              <Paper elevation={3} sx={paperStyle}>
                <Typography variant="h5" sx={headingStyle}>
                  {months[dateVar.getMonth()]} {dateVar.getDate()}
                </Typography>
                {today.map((value, index) => <Log key={index} creator={user.result._id} data={value} />)}
              </Paper>
            </Grid>

            {/* Display monthly summary of exercises */}
            <Grid item style={{margin: 'auto'}} xs={12} sm={12} md={4} lg={4}>
              <Paper elevation={3} sx={paperStyle}>
                <Typography variant="h5" sx={headingStyle}>
                  {months[dateVar.getMonth()] + " Summary"}
                </Typography>
                {monthKeys.map((index) => <Typography key={index} style={{textAlign:'center'}}> {monthSummary.get(index)[1]}: {monthSummary.get(index)[0]} </Typography>)}
              </Paper>
            </Grid>
          </Grid>

          {/* Stats component to display overall statistics */}
          <Grid item style={{margin: 'auto'}} xs={12} sm={12} md={12} lg={12}>
            <Stats logMap={logMap} total2Months={total2Months} totalWeek={totalWeek}/>
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default Logs;
