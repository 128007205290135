import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
  console.log('Running in development mode');
}

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// intercepts every request and adds the token to the header
API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});


// user api calls
export const getUserById = (id) => API.get(`/user/${id}`);
export const getUsersByIds = (ids, page, limit) => API.post(`/user/users?page=${page}&limit=${limit}`, ids);
export const getUserByEmail = (email) => API.get(`/user/email/${email}`);
export const updateUserProfile = (updatedUser) => API.patch('/user/update', updatedUser);
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const verifyEmail = (token) => API.post('/user/verify-email', token);
export const recoverEmail = (email) => API.post('/user/recover-email', email);
export const changePassword = (formData) => API.post('/user/change-password', formData);
export const checkToken = (formData) => API.post('/user/check-token', formData);

// followers
export const getFollowersByUserId = (id) => API.get(`/user/${id}/followers`)
export const getFollowingByUserId = (id) => API.get(`/user/${id}/following`)
export const getIncomingByUserId = (id) => API.get(`/user/${id}/incoming`)
export const getOutgoingByUserId = (id) => API.get(`/user/${id}/outgoing`)
export const followUser = (id) => API.post(`/user/${id}/follow`);
export const withdrawRequest = (id) => API.post(`user/${id}/withdraw`);
export const unfollowUser = (id) => API.post(`/user/${id}/unfollow`);
export const acceptFollowRequest = (id) => API.post(`/user/${id}/accept-follow`);
export const declineFollowRequest = (id) => API.post(`/user/${id}/decline-follow`);
export const removeFollower = (id) => API.post(`/user/${id}/remove-follow`);
export const searchUsers = (query) => API.get(`/user/search?query=${query}`);
export const suggestUsersToFollow = () => API.get('/user/suggestions');

// bookmark api calls (now integrated into user routes)
export const getBookmarkedExercisesByCreator = (page = 1, limit = 8) => API.get(`/user/bookmarked/exercises?page=${page}&limit=${limit}`);
export const getBookmarkedWorkoutsByCreator = (page = 1, limit = 8) => API.get(`/user/bookmarked/workouts?page=${page}&limit=${limit}`);
export const getExerciseBookmarksByCreator = () => API.get('/user/bookmarks/exercises');
export const getWorkoutBookmarksByCreator = () => API.get('/user/bookmarks/workouts');
export const getExerciseBookmarksWithTitles = () => API.get('/user/bookmarks/exercisesWithTitles');
export const getWorkoutBookmarksWithTitles = () => API.get('/user/bookmarks/workoutsWithTitles');
export const toggleBookmark = (data) => API.post('/user/bookmarks/toggle', data);
export const deleteBookmark = (id) => API.delete(`/user/bookmarks/${id}`);
export const deleteBookmarksByExerciseId = (exerciseId) => API.delete(`/user/bookmarks/exercises/${exerciseId}`);
export const deleteBookmarksByWorkoutId = (workoutId) => API.delete(`/user/bookmarks/workouts/${workoutId}`);

// exercise api calls
export const getHomepageExercises = () => API.get('/exercise/homepage');
export const getHomepageExercisesForGuest = () => API.get('/exercise/homepage/guest');
export const getTrendingExercises = (page = 1, limit = 7) => API.get(`/exercise/trending?page=${page}&limit=${limit}`);
export const getExercisesByCreator = (creatorId, page = 1, limit = 12) => API.get(`/exercise/creator/${creatorId}?page=${page}&limit=${limit}`);
export const searchExercises = (query, page = 1, limit = 10) => API.get(`/exercise/search?query=${query}&page=${page}&limit=${limit}`);
export const getExercisesFromArray = (array) => API.get(`/exercise/fromArray/${array.join(',')}`);
export const getExerciseById = (id) => API.get(`/exercise/${id}`);
export const getSuggestedExercises = () => API.get('/exercise/suggested');
export const createExercise = (formData) => API.post('/exercise/', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateExercise = (id, formData) => API.patch(`/exercise/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteExercise = (id) => API.delete(`/exercise/${id}`);
export const likeExercise = (id) => API.post(`/exercise/${id}/like`);
export const incrementExerciseRuns = (id) => API.post(`/exercise/${id}/run`);

// workout api calls
export const getHomepageWorkouts = () => API.get('/workout/homepage');
export const getHomepageWorkoutsForGuest = () => API.get('/workout/homepage/guest');
export const getTrendingWorkouts = (page = 1, limit = 7) => API.get(`/workout/trending?page=${page}&limit=${limit}`);
export const getWorkoutsByCreator = (creatorId, page = 1, limit = 12) => API.get(`/workout/creator/${creatorId}?page=${page}&limit=${limit}`);
export const searchWorkouts = (query, page = 1, limit = 10) => API.get(`/workout/search?query=${query}&page=${page}&limit=${limit}`);
export const getWorkoutsFromArray = (array) => API.get(`/workout/fromArray/${array.join(',')}`);
export const getWorkoutById = (id) => API.get(`/workout/${id}`);
export const getSuggestedWorkouts = () => API.get('/workout/suggested');
export const createWorkout = (formData) => API.post('/workout/', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateWorkout = (id, formData) => API.patch(`/workout/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteWorkout = (id) => API.delete(`/workout/${id}`);
export const likeWorkout = (id) => API.post(`/workout/${id}/like`);
export const incrementWorkoutRuns = (id) => API.post(`/workout/${id}/run`);

// comment api calls
export const createComment = (data) => API.post('/comment', data);
export const getCommentsByExercise = (exerciseId, page = 1, limit = 10) => API.get(`/comment/exercise/${exerciseId}?page=${page}&limit=${limit}`);
export const getCommentsByWorkout = (workoutId, page = 1, limit = 10) => API.get(`/comment/workout/${workoutId}?page=${page}&limit=${limit}`);
export const getCommentsByVideo = (videoId, page = 1, limit = 10) => API.get(`/comment/video/${videoId}?page=${page}&limit=${limit}`);
export const deleteComment = (id) => API.delete(`/comment/${id}`);
export const updateComment = (id, data) => API.put(`/comment/${id}`, data);
export const getCommentById = (id) => API.get(`/comment/${id}`);

// video api calls
export const getHomepageVideos = () => API.get('/video/homepage');
export const getHomepageVideosForGuest = () => API.get('/video/homepage/guest');
export const getTrendingVideos = (page = 1, limit = 4) => API.get(`/video/trending?page=${page}&limit=${limit}`);
export const getVideosByCreator = (creatorId, page = 1, limit = 12) => API.get(`/video/creator/${creatorId}?page=${page}&limit=${limit}`);
export const searchVideos = (query, page = 1, limit = 10) => API.get(`/video/search?query=${query}&page=${page}&limit=${limit}`);
export const getVideoById = (id) => API.get(`/video/${id}`);
export const createVideo = (data) => API.post('/video/create', data);
export const updateVideo = (id, data) => API.patch(`/video/edit/${id}`, data);
export const deleteVideo = (id) => API.delete(`/video/${id}`);
export const likeVideo = (id) => API.post(`/video/${id}/like`);
export const incrementVideoViews = (id) => API.post(`/video/${id}/view`);

// log api calls
export const createLog = (data) => API.post('/log', data);
export const deleteLog = (id) => API.delete(`/log/${id}`);
export const updateLogVisibility = (id, data) => API.patch(`/log/${id}/visibility`, data);
export const getLogsByCreator = (viewSelf, month, page, limit) => API.get(`/log/creator/?viewSelf=${viewSelf}&month=${month}&page=${page}&limit=${limit}`);
export const getTodayLogsByWorkoutId = (workoutId, page, limit) => API.get(`/log/today/${workoutId}?page=${page}&limit=${limit}`);
export const getMonthLogsByWorkoutId = (workoutId, page, limit) => API.get(`/log/month/${workoutId}?page=${page}&limit=${limit}`);
export const getMonthLogsByCreator = (creatorId, page, limit) => API.get(`/log/month/creator/${creatorId}?page=${page}&limit=${limit}`);
export const getMonthLogsByCreatorForUser = (creatorId, page, limit) => API.get(`/log/month/creator/user/${creatorId}?page=${page}&limit=${limit}`);
export const getLogsByExercise = (exerciseId, page, limit) => API.get(`/log/exercise/${exerciseId}?page=${page}&limit=${limit}`);
export const getTwoMonthLogsByCreator = (creatorId, page, limit) => API.get(`/log/two-month/creator/${creatorId}?page=${page}&limit=${limit}`);
export const getLogsByMonthAndWorkouts = (month, programId) => API.get(`/log/logsByMonthAndWorkouts/?&month=${month}&programId=${programId}`);
export const uploadLogToProgram = (logId, programId) => API.post(`log/program/${logId}?programId=${programId}`);
export const removeLogFromProgram = (logId, programId) => API.post(`log/removeProgram/${logId}?programId=${programId}`);
export const getLogsByProgram = (month, programId, memberId) => API.get(`/log/logsByProgram/?&month=${month}&programId=${programId}&memberId=${memberId}`);
export const getCompetitionResults = (programId) => API.get(`/log/competitions/${programId}`);

// stats api calls
export const createStats = () => API.post('/stats');
export const incrementStats = (data) => API.post('/stats/increment', data);
export const getStatsByCreator = (creatorId) => API.get(`/stats/creator?creator=${creatorId}`);

// program api calls
export const getHomepagePrograms = () => API.get('/program/homepage');
export const getHomepageProgramsForGuest = () => API.get('/program/homepage/guest');
export const getTrendingPrograms = (page = 1, limit = 4) => API.get(`/program/trending?page=${page}&limit=${limit}`);
export const getProgramsByInvitedUser = (page = 1, limit = 4) => API.get(`/program/invited?page=${page}&limit=${limit}`);
export const getProgramsByMember = (page = 1, limit = 4) => API.get(`/program/joined?page=${page}&limit=${limit}`);
export const getProgramsByRequestedUser = (page = 1, limit = 4) => API.get(`/program/requested?page=${page}&limit=${limit}`);
export const isMember = (id) => API.get(`/program/${id}/isMember`);
export const getSuggestedPrograms = (page = 1, limit = 4) => API.get(`/program/suggested?page=${page}&limit=${limit}`);
export const createProgram = (data) => API.post('/program', data);
export const deleteProgram = (id) => API.delete(`/program/${id}`);
export const getMembers = (id) => API.get(`/program/${id}/members`);
export const getInvitedUsers = (id) => API.get(`/program/${id}/invitedUsers`);
export const getRequestedUsers = (id) => API.get(`/program/${id}/requestedUsers`);
export const joinProgram = (id) => API.post(`/program/${id}/join`);
export const addJoinRequest = (id) => API.post(`/program/${id}/joinRequest`);
export const removeJoinRequest = (id) => API.post(`/program/${id}/removeJoinRequest`);
export const denyJoinRequest = (id, data) => API.post(`program/${id}/denyJoinRequest`, { data })
export const addMember = (id, data) => API.post(`/program/${id}/member`, { data });
export const removeMember = (id, data) => API.post(`/program/${id}/removeMember`, { data });
export const leaveProgram = (id) => API.post(`/program/${id}/leave`);
export const scheduleWorkout = (id, data) => API.post(`/program/${id}/schedule`, data);
export const removeWorkout = (id, data) => API.delete(`/program/${id}/workout`, { data });
export const unscheduleWorkout = (id, data) => API.post(`/program/${id}/unschedule`, data);
export const addCompetition = (id, data) => API.post(`/program/${id}/competition`, {data});
export const removeCompetition = (id, data) => API.post(`/program/${id}/removeCompetition`, { data });
export const updateVisibility = (id) => API.patch(`/program/${id}/visibility`);
export const addMemberByJoinCode = (joinCode) => API.post(`/program/${joinCode}/joinByCode`);
export const addInvitedUser = (id, data) => API.post(`/program/${id}/invitation`, { data });
export const removeInvitedUser = (id, data) => API.post(`/program/${id}/removeInvitation`, { data });
export const acceptProgramInvitation = (id) => API.post(`/program/${id}/accept`);
export const getProgramsByCreator = (creatorId, page = 1, limit = 12) => API.get(`/program/creator/${creatorId}?page=${page}&limit=${limit}`);
export const getProgramById = (id) => API.get(`/program/${id}`);
export const searchPrograms = (query, page = 1, limit = 10) => API.get(`/program/search?query=${query}&page=${page}&limit=${limit}`);
export const getExercisesByProgramId = (id) => API.get(`/program/${id}/exercises`);
export const getWorkoutsByProgramId = (id) => API.get(`program/${id}/workouts`);

// payment api calls
export const getStripeAccountStatus = () => API.get('/payment/account-status');
export const getPaymentAccount = () => API.get('/payment/account');
export const createStripeConnectedAccount = (data) => API.post('/payment/create-connected-account', data); // create connected account from email
export const getStripeLoginLink = () => API.get('/payment/get-login-link'); // get login link for stripe
export const createSubscription = (data) => API.post('/payment/create-subscription', data); // create subscription from program id
export const cancelSubscription = (data) => API.post('/payment/cancel-subscription', data); // cancel subscription from subscription id
export const checkSubscriptionStatus = (data) => API.post('/payment/check-subscription-status', data); // check subscription status from program id
export const postPayment = (id) => API.post(`/payment/create-checkout-session`, {id});