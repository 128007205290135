import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStripeConnectedAccount, getStripeAccountStatus } from '../../actions/payment';
import { getUserById } from '../../actions/user';
import { 
  Box, 
  Container, 
  Typography, 
  Paper, 
  Button, 
  Link,
  CircularProgress 
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

const CoachDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const accountDetails = useSelector((state) => state.payment.accountDetails);

  // First get current user data
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile?.result?._id) {
      console.log('Getting user data for:', profile.result._id);
      dispatch(getUserById(profile.result._id));
    }
  }, []);

  // Then load account status once we have the user
  useEffect(() => {
    if (currentUser?._id) {
      console.log('Getting account status for user:', currentUser._id);
      dispatch(getStripeAccountStatus())
        .then(result => {
          console.log('Account status loaded:', result);
        })
        .catch(error => {
          console.error('Error loading account status:', error);
        });
    }
  }, [currentUser]);

  console.log('Render state:', { currentUser, accountDetails });

  const handleOnboarding = async () => {
    if (!currentUser) return;
    
    setIsLoading(true);
    try {
      const result = await dispatch(createStripeConnectedAccount({
        email: currentUser.email,
        userId: currentUser._id
      }));
      
      if (result?.redirectUrl) {
        window.location.href = result.redirectUrl;
      }
    } catch (error) {
      console.error('Error starting onboarding:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinueOnboarding = () => {
    if (accountDetails?.onboardingUrl) {
      window.location.href = accountDetails.onboardingUrl;
    }
  };

  // Modify loading check to show what's missing
  if (!currentUser) {
    return (
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 2
      }}>
        <CircularProgress />
        <Typography>Loading user data...</Typography>
        <Typography color="text.secondary">
          Debug info: {JSON.stringify({ currentUser, accountDetails }, null, 2)}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" sx={{ mb: 4, fontWeight: 'bold', color: 'white' }}>
          Coach Dashboard
        </Typography>
        
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3, fontWeight: 600 }}>
            Payment Setup
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <Typography color="text.secondary" variant="body2">
              Debug info: {JSON.stringify({ currentUser, accountDetails }, null, 2)}
            </Typography>
          </Box>
          
          {!accountDetails?.accountId ? (
            <Box>
              <Typography sx={{ mb: 3 }}>
                Set up payments to start earning from your programs
              </Typography>
              <Button
                variant="contained"
                onClick={handleOnboarding}
                disabled={isLoading}
                sx={{ 
                  textTransform: 'none',
                  '&.Mui-disabled': { 
                    backgroundColor: 'action.disabledBackground' 
                  }
                }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    Setting up...
                  </Box>
                ) : (
                  'Set up payments'
                )}
              </Button>
            </Box>
          ) : !accountDetails.isComplete ? (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: 'warning.main' }}>
                <WarningIcon sx={{ mr: 1 }} />
                <Typography color="warning.main">
                  Account setup incomplete
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={handleContinueOnboarding}
                color="warning"
                sx={{ textTransform: 'none' }}
              >
                Complete account setup
              </Button>
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: 'success.main' }}>
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography color="success.main">
                  Payment account connected
                  {!accountDetails.isEnabled && ' (pending verification)'}
                </Typography>
              </Box>
              {accountDetails.loginUrl && (
                <Link
                  href={accountDetails.loginUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  View Stripe dashboard
                </Link>
              )}
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default CoachDashboard;