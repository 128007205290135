import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

export const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return; // Ensure Stripe.js is loaded

    setLoading(true);

    const card = elements.getElement(CardElement);

    // Call your backend to create a subscription
    const response = await fetch('/create-subscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, plan }),
    });
    const { clientSecret } = await response.json();

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card },
    });

    setLoading(false);

    if (error) {
      console.error(error.message);
    } else {
      console.log('Subscription created successfully:', paymentIntent);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        margin: 'auto',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" textAlign="center">
        Subscribe to a Plan
      </Typography>

      <TextField
        label="Name"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      <TextField
        label="Email"
        fullWidth
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <FormControl fullWidth>
        <InputLabel id="plan-label">Subscription Plan</InputLabel>
        <Select
          labelId="plan-label"
          value={plan}
          onChange={(e) => setPlan(e.target.value)}
          required
        >
          <MenuItem value="basic">Basic - $10/month</MenuItem>
          <MenuItem value="premium">Premium - $20/month</MenuItem>
          <MenuItem value="pro">Pro - $50/month</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
        <CardElement />
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!stripe || loading}
      >
        {loading ? 'Processing...' : 'Subscribe'}
      </Button>
    </Box>
  );
}

export default SubscriptionForm;
