import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { postPayment } from '../../actions/payment';
import DeleteIcon from '@mui/icons-material/Delete';



const Payment = () => {
    const [cart, setCart] = useState([]);
    const exercise_premium = { id: 1, name: 'Exercise Premium', description: 'Unlimited exercise and workout creation',price: 4.99, quantity: 1 };
    const coaching_premium = { id: 2, name: 'Coaching Premium', description: 'Unlimited subscriptions to coaching programs', price: 4.99, quantity: 1 };
    const logging_premium = { id: 3, name: 'Logging Premium', description: 'Unlimited logging and sharing of recorded workouts',price: 6.99, quantity: 1 };
    const verified = { id: 4, name: 'VERIFIED', description: 'Unlimited access to exercise and workout creation, subscriptions to coaching programs, and logging. Additional perks include upgraded stats.', price: 11.99, quantity: 1 };
    const products = [exercise_premium, coaching_premium, logging_premium, verified];
    
    const theme = useTheme();
    
    const addToCart = (product) => {
      setCart(prevCart => {
        if (prevCart.find(item => item.id === product.id) || prevCart.find(item => item.id === 4)) {
          return prevCart; // Do not add the product if it's already in the cart
        }
        prevCart = [...prevCart, product]
        if (
          prevCart.find(item => item.id === 1) &&
          prevCart.find(item => item.id === 2) && 
          prevCart.find(item => item.id === 3)
        )
        {
          return [verified]
        }
        return prevCart;
      });
    };

    const removeFromCart = (productId) => {
        setCart(prevCart=>prevCart.filter(item => item.id !== productId));
    };
    const dispatch = useDispatch();
    
    const payment = useSelector((state) => state.payment.payment);
    const user = JSON.parse(localStorage.getItem('profile'));
    useEffect(()=> {
        if (payment != null) {
          console.log(payment)
          if (payment.url) window.location = payment.url;
        }
        
    }, [payment]);
    const pay = () => {
        if (user == null) {
            window.location = '/auth';
            return;
        }
        console.log(cart);
        const filteredCart = cart.map((item) => ({id: item.id, quantity: item.quantity}));
        console.log(filteredCart)
        dispatch(postPayment(filteredCart, user.result.email));
    }

    const textStyle = {
        color: theme.palette.primary.main,
    };

    return (
        <>
        <Container>
        <Typography sx={textStyle} variant="h3" gutterBottom>
          Shopping Cart
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography sx = {textStyle} variant="h4">Products</Typography>
            <List>
              {products.map(product => (
                <ListItem key={product.id}>
                  <Grid container alignItems="center">
                      <Grid item xs={9}>
                    <ListItemText
                      sx={textStyle}
                      primary={product.name}
                      secondary = {
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            {product.description}
                          </Typography>
                          <br />
                          {`$${product.price}`}
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'right' }}> 
                    <ListItemSecondaryAction>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => addToCart(product)}
                      >
                        Add to Cart
                      </Button>
                    </ListItemSecondaryAction>
                    </Grid> 
                  </Grid>
                  
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={textStyle} variant="h4">Cart</Typography>
            <Paper style={{ padding: 16 }}>
              <List>
                {cart.length === 0 ? (
                  <Typography variant="h6">Your cart is empty</Typography>
                ) : (
                  cart.map(item => (
                    <ListItem key={item.id}>
                      <ListItemText
                        primary={item.name}
                        secondary={`$${item.price}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFromCart(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                )}
              </List>
              <Typography variant="h6">
                Total: ${cart.reduce((total, item) => total + item.price, 0)}
              </Typography>
              {
                cart.reduce((total, item) => total + item.price, 0) != 0 &&
                <Button style={{marginTop:"10px"}} color="primary" variant="contained" onClick={pay}>
                  Checkout
                </Button>
              }
              
            </Paper>
          </Grid>
        </Grid>
      </Container>
      </>
    );
}


export default Payment;