import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Paper, TextField, Box, Container, Typography, Stack, Switch, InputAdornment, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { createProgram } from '../../../actions/program';
import { getStripeAccountStatus } from '../../../actions/payment';
import { useTheme } from '@mui/material/styles';
import FileInput from '../../Form/FileInput';

const ProgramForm = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [programData, setProgramData] = useState({
    title: "",
    description: "",
    visibility: true,
    thumbnail: null,
    price: "0.00",
  });
  const [rawThumbnail, setRawThumbnail] = useState(null);
  const [priceError, setPriceError] = useState(false);

  // states for Stripe account details
  const [stripeError, setStripeError] = useState('');
  const accountDetails = useSelector((state) => state.payment.accountDetails);

  useEffect(() => {
    dispatch(getStripeAccountStatus());
  }, [dispatch]);  

  const toggle = (event) => {
    setProgramData({ ...programData, visibility: !programData.visibility });
  };

  const handlePriceChange = (e) => {
    let value = e.target.value;
    
    // Reset stripe error when price is changed
    setStripeError('');
  
    // Convert to float for validation
    const floatValue = parseFloat(value) || 0;
  
    // Check if trying to set paid program without Stripe
    if (floatValue > 0 && (!accountDetails?.accountId)) {
      setStripeError('You need to set up payments to create paid programs');
      setPriceError(true);
      return;
    }
  
    // Check if trying to set paid program with incomplete Stripe setup
    if (floatValue > 0 && accountDetails?.accountId && !accountDetails?.isComplete) {
      setStripeError('Please complete your payment account setup before creating paid programs');
      setPriceError(true);
      return;
    }
  
    // Validate input: Allow numbers and at most two decimal points
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      // Check if the price is either 0.00 or between 2.00 and 50.00
      if ((floatValue === 0 || (floatValue >= 2.00 && floatValue <= 50.00)) || value === "") {
        setPriceError(false);
        setProgramData({ ...programData, price: value });
      } else {
        setPriceError(true);
        setProgramData({ ...programData, price: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Double check stripe status for paid programs
    if (parseFloat(programData.price) > 0) {
      if (!accountDetails?.accountId) {
        setStripeError('You need to set up payments to create paid programs');
        return;
      }
      if (!accountDetails?.isComplete) {
        setStripeError('Please complete your payment account setup before creating paid programs');
        return;
      }
    }
  
    if (!priceError && !stripeError) {
      try {
        const result = await dispatch(createProgram({
          ...programData,
          title: programData.title.length > 0 ? programData.title : "Untitled Program",
          thumbnail: rawThumbnail,
          price: parseFloat(programData.price) || 0
        }));
  
        if (result?._id) {
          setProgramData({ title: "", description: "", visibility: true, thumbnail: null, price: "0.00" });
          navigate('/programs/' + result._id);
        }
      } catch (error) {
        if (error.response?.data?.message?.includes('Stripe')) {
          setStripeError(error.response.data.message);
        }
      }
    }
  };

  const buttonStyle = {
    margin: '8px',
    width: '200px',
  };

  const paperStyle = {
    padding: theme.spacing(2),
    borderRadius: 15,
    backgroundColor: theme.palette.background.paper,
  };

  const formStyle = {
    margin: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  };

  return (
    <>
      {user !== null && (
        <Container sx={{ mt: theme.spacing(1) }}>
          <Paper style={paperStyle} elevation={6}>
            <form autoComplete="off" noValidate style={formStyle} onSubmit={handleSubmit}>
              <Box sx={boxStyle}>
                <Typography color='primary' variant="h6">Program Builder</Typography>
                <TextField sx={{ m: 1 }} name="title" variant="outlined" label="Program Title" fullWidth multiline rows={1} value={programData.title} onChange={(e) => setProgramData({ ...programData, title: e.target.value })} />
                <TextField sx={{ m: 1 }} name="description" variant="outlined" label="Description" fullWidth multiline rows={4} value={programData.description} onChange={(e) => setProgramData({ ...programData, description: e.target.value })} />
                
                <>
                  <TextField
                    sx={{ m: 1, width: '200px' }}
                    size="small"
                    name="price"
                    variant="outlined"
                    label="Monthly subscription price"
                    value={programData.price}
                    error={priceError || !!stripeError}
                    helperText={
                      stripeError ? (
                        <span>
                          {stripeError}{' '}
                          <Link 
                            to="/dashboard"
                            style={{ color: theme.palette.primary.main }}
                          >
                            Set up payments
                          </Link>
                        </span>
                      ) :
                      priceError ? "Price must be $0.00 or between $2.00 and $50.00" :
                      "Gains Coach, LLC takes a 30% cut. Leave blank or $0 for free programs."
                    }
                    onChange={handlePriceChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />

                  {accountDetails?.accountId && !accountDetails.isComplete && (
                    <Button
                      component={Link}
                      to="/dashboard"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1, mb: 2 }}
                    >
                      Complete Payment Setup
                    </Button>
                  )}
                </>

                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                  <Typography variant="body1">Private</Typography>
                  <Switch
                    checked={programData.visibility}
                    onChange={toggle}
                  />
                  <Typography variant="body1">Public</Typography>
                </Stack>

                <FileInput val="" base64={false} onDone={(file) => setRawThumbnail(file)}>
                  Upload Thumbnail
                </FileInput>

                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={handleSubmit}
                  disabled={priceError || !!stripeError}
                >
                  Create Program
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default ProgramForm;