import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Pagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getCommentsByExercise, getCommentsByWorkout, getCommentsByVideo, createComment, updateComment, deleteComment } from '../../actions/comment';
import moment from 'moment';


const CommentSection = ({ type, id, programId }) => {
  const dispatch = useDispatch();
  const { exerciseComments, workoutComments, videoComments } = useSelector((state) => state.comment);
  const [commentData, setCommentData] = useState({ content: '' });
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);
  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (type === 'exercise') {
      dispatch(getCommentsByExercise(id, page));
    } else if (type === 'workout') {
      dispatch(getCommentsByWorkout(id, page));
    } else if (type === 'video') {
      dispatch(getCommentsByVideo(id, page));
    }
  }, [dispatch, id, page, type]);

  const comments = type === 'exercise' ? exerciseComments?.comments : (type === 'workout') ? workoutComments?.comments : videoComments?.comments;
  const totalComments = type === 'exercise' ? exerciseComments?.totalComments : (type === 'workout') ? workoutComments?.totalComments : videoComments?.totalComments;
  const totalPages = Math.ceil((totalComments || 0) / 10);

  const clear = () => {
    setCurrentId(0);
    setCommentData({ content: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createComment({ 
        ...commentData, 
        [type]: id,
        programId
      }));
    } else {
      dispatch(updateComment(currentId, commentData));
    }
    clear();
  };

  const handleDelete = (id) => {
    dispatch(deleteComment(id));
  };

  const handleEdit = (comment) => {
    setCurrentId(comment._id);
    setCommentData({ content: comment.content });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6" sx={{ marginBottom: '5px' }}>Comments</Typography>
      <form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <TextField
          name="comment"
          variant="outlined"
          label="Comment"
          fullWidth
          multiline
          rows={4}
          value={commentData.content}
          onChange={(e) => setCommentData({ ...commentData, content: e.target.value })}
        />
        <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" size="large" type="submit" disabled={!commentData.content}>
          {currentId ? 'Update' : 'Post'}
        </Button>
      </form>

      <List>
        {comments?.map((comment) => (
          <ListItem 
            key={comment._id}
            sx={{
              backgroundColor: comment.pinned ? '#f5f5f5' : 'transparent',
              borderLeft: comment.pinned ? '4px solid #1976d2' : 'none'
            }}
          >
            <ListItemText
              primary={
                <Typography>
                  {comment.pinned && (
                    <Typography
                      component="span"
                      color="primary"
                      sx={{ mr: 1, fontWeight: 'bold' }}
                    >
                      Program Creator •
                    </Typography>
                  )}
                  {comment.content}
                </Typography>
              }
              secondary={`${comment.creator.username} • ${moment(comment.createdAt).fromNow()}`}
            />
            <ListItemSecondaryAction>
              {user?.result?._id === comment.creator._id && (
                <>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(comment)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(comment._id)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
    </Paper>
  );
};

export default CommentSection;
