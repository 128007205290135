import { 
  CREATE_STRIPE_CONNECTED_ACCOUNT,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_STATUS,
  GET_STRIPE_LOGIN_LINK,
  GET_PAYMENT_ACCOUNT,
  GET_STRIPE_ACCOUNT_STATUS,
  SUBSCRIPTION_ERROR
} from '../constants/actionTypes';
  
const initialState = {
  stripeLoginLink: null,
  payment: null,
  accountDetails: null,
  error: null,
  subscriptionError: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STRIPE_ACCOUNT_STATUS:
      return {
        ...state,
        accountDetails: action.payload
      };   
    case GET_PAYMENT_ACCOUNT:
      return {
        ...state,
        accountDetails: action.payload
      };
    case GET_STRIPE_LOGIN_LINK:
      return {
        ...state,
        stripeLoginLink: action.payload.url
      };
    case CREATE_STRIPE_CONNECTED_ACCOUNT:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          accountId: action.payload.data.account.id,
          isComplete: false
        }
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionError: null
      };
    case SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscriptionError: action.payload
      };
    case CANCEL_SUBSCRIPTION:
    case CHECK_SUBSCRIPTION_STATUS:
      return {...state, payment: action.payload.data};
    default:
      return state;
  }
};
  
export default paymentReducer;